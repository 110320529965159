<mat-toolbar class="app-toolbar">
  <mat-toolbar-row>

    <button mat-icon-button
            class="mr-3"
            (click)="onToggleSideNavClick()">
      <lib-icon icon="menu"></lib-icon>
    </button>

    <div class="app-logo-container app-cursor-pointer h-100 d-flex align-items-center" [routerLink]="['/']">
      <img src="/assets/img/logo_accexpert.jpg"
           class="app-logo"
           alt="accexpert">
    </div>

    <div class="app-search-wrapper d-none d-md-flex">
      <input type="text"
             [formControl]="searchControl"
             (focusin)="onCreateOverlay()"
             autocomplete="off"
             placeholder="Wpisz kod części...">
      <lib-icon icon="search"
                matSuffix
                class="app-icon-16 mx-2"
                color="primary"></lib-icon>
    </div>

    <div class="flex-grow-1"></div>

    <!-- <div class="d-flex align-items-center mr-2 mr-md-4"> -->
<!--      <button mat-stroked-button type="button"-->
<!--              class="app-xs mr-2 app-white-btn">PLN</button>-->
      <!-- <button mat-stroked-button type="button"
              [matMenuTriggerFor]="localeMenu"
              class="app-xs app-white-btn text-uppercase">{{ locale$ | async }}</button>

      <mat-menu #localeMenu>
        <button mat-menu-item
                (click)="onChangeLocale('pl')"
                type="button">PL</button>
        <button mat-menu-item
                (click)="onChangeLocale('en')"
                type="button">EN</button>
      </mat-menu> -->
    <!-- </div> -->

    <button mat-button [matMenuTriggerFor]="userMenu"
            class="app-sm px-0 app-user-info-btn">
      <div class="d-flex align-items-center">
        <lib-icon class="mr-2 app-icon-22" icon="person"></lib-icon>
        <div class="d-flex align-items-center">
          <div class="text-left">
            <div class="text-truncate app-user-name">{{ userName$ | async }}</div>
            <small class="d-none d-md-block font-weight-light text-truncate">
              <ng-container *ngFor="let role of userRoles$ | async; first as isFirst; trackBy: trackById">
                <ng-container *ngIf="!isFirst">, </ng-container>
                {{ (role | roleInfo | async)?.name }}
              </ng-container>
            </small>
          </div>
          <lib-icon class="ml-1 app-icon-16" icon="more_vertical"></lib-icon>
        </div>
      </div>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav
    [mode]="sideNavMode$ | async"
    [opened]="isSideNavOpened$ | async"
    [fixedTopGap]="46"
    [fixedInViewport]="true"
    (closedStart)="onSidenavClose()">

    <div class="app-sidenav-menu app-sidenav-menu-white app-menu-container pt-2">
      <app-menu [menu]="sideNavMenu$ | async"></app-menu>
    </div>
  </mat-sidenav>

  <mat-sidenav-content class="app-page-toolbar-offset-top">
    <div class="container-fluid pt-3">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<mat-menu #userMenu="matMenu">
  <button mat-menu-item (click)="onLogout()">
    <lib-icon icon="dismiss"></lib-icon>
    Wyloguj się
  </button>
</mat-menu>
