import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LibIconModule, LibMaterialSharedModule, LibTableModule } from '@industi/ngx-modules';
import { DimensionDataTableComponent } from './components/dimension-data-table/dimension-data-table.component';
import { FiltersButtonComponent } from './components/filters-button/filters-button.component';
import { GlobalSearchResultComponent } from './components/global-search-result/global-search-result.component';
import { PriceDataTableComponent } from './components/price-data-table/price-data-table.component';
import { AppSeakPaginationModule } from './modules/app-seak-pagination/app-seak-pagination.module';
import { TypeToComponentByElementTypePipe } from './pipes/component-by-element-type.pipe';
import { LocationInfoPipe } from './pipes/location-info.pipe';
import { NoValuePipe } from './pipes/no-value.pipe';
import { PriceInfoByCurrencyPipe } from './pipes/price-info-by-currency.pipe';
import { ReplaceTypeInfoPipe } from './pipes/replace-type-info.pipe';
import { AppRoleInfoPipe } from './pipes/role-info.pipe';
import { SanitizeUrlPipe } from './pipes/sanitize-url.pipe';
import { ServiceProjectStatusInfoPipe } from './pipes/status-info.pipe';

@NgModule({
  declarations: [
    PriceDataTableComponent,
    SanitizeUrlPipe,
    AppRoleInfoPipe,
    NoValuePipe,
    ReplaceTypeInfoPipe,
    PriceInfoByCurrencyPipe,
    LocationInfoPipe,
    ServiceProjectStatusInfoPipe,
    DimensionDataTableComponent,
    GlobalSearchResultComponent,
    FiltersButtonComponent,
    TypeToComponentByElementTypePipe
  ],
  imports: [
    CommonModule,
    LibMaterialSharedModule,
    LibTableModule,
    AppSeakPaginationModule,
    RouterModule,
    LibIconModule,
    ReactiveFormsModule
  ],
  exports: [
    PriceDataTableComponent,
    SanitizeUrlPipe,
    AppRoleInfoPipe,
    ServiceProjectStatusInfoPipe,
    NoValuePipe,
    ReplaceTypeInfoPipe,
    LocationInfoPipe,
    PriceInfoByCurrencyPipe,
    DimensionDataTableComponent,
    TypeToComponentByElementTypePipe,
    FiltersButtonComponent
  ],
  providers: [
    SanitizeUrlPipe,
    AppRoleInfoPipe,
    LocationInfoPipe
  ]
})
export class SharedModule {
}
