import { Observable, throwError } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { getLibAuthAccessToken, LibAuthSetTokenAction } from 'app-store-auth';
import { ResponseStatus } from '../../shared/enums/response-status.enum';
import { ByKey } from '@industi/ngx-common';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../shared/app-global';
import { getEbayToken } from '../ebay/ebay.reducer';
import { OutputTokenResponse } from './models/output-token-response';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private accessToken$: Observable<string>;
  // private ebayAccessToken$: Observable<OutputTokenResponse>;

  // readonly excludedUrls = [
  //   environment?.hosts?.ebayApiUrl
  // ];

  constructor(
    private store: Store<ByKey>,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.accessToken$ = this.store.pipe(select(getLibAuthAccessToken));
    // this.ebayAccessToken$ = this.store.pipe(select(getEbayToken));
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if (req && req.url && this.excludedUrls.some((item: string) => req.url.indexOf(item) >= 0)) {
    //   return this.ebayAccessToken$.pipe(
    //     first(),
    //     switchMap((token: OutputTokenResponse) => {
    //       if (token) {
    //         const headers = req.headers.set('Authorization', 'Bearer ' + token?.token);
    //         req = req.clone({ headers });
    //       }
    //       return next.handle(req);
    //     })
    //   );
    // } else {
      return this.accessToken$.pipe(
        first(),
        switchMap((token: string) => {
          if (token) {
            const headers = req.headers.set('Authorization', 'Bearer ' + token);
            req = req.clone({ headers });
          }

          return next.handle(req).pipe(
            catchError(error => {
              switch (error.status) {

                // 401
                case ResponseStatus.UNAUTHORIZED:
                  this.store.dispatch(new LibAuthSetTokenAction({ value: null }));
                  this.router.navigate(['']);
                  return throwError(error);

                // 500
                case ResponseStatus.INTERNAL_SERVER_ERROR:
                  this.snackBar.openFromComponent(LibSnackbarComponent, {
                    data: {
                      message: 'Wystąpił błąd podczas komunikacji z serwerem.',
                      type: 'error'
                    },
                    duration: AppGlobal.defaultSnackBarDuration
                  });
                  return throwError(null);

                // 405
                case ResponseStatus.METHOD_NOT_ALLOWED:
                  this.snackBar.openFromComponent(LibSnackbarComponent, {
                    data: {
                      message: 'Brak dostępu do zasobu.',
                      type: 'error'
                    },
                    duration: AppGlobal.defaultSnackBarDuration
                  });
                  return throwError(null);

                // 503 & 504
                case ResponseStatus.SERVICE_UNAVAILABLE:
                case ResponseStatus.GATEWAY_TIMEOUT:
                  this.snackBar.openFromComponent(LibSnackbarComponent, {
                    data: {
                      message: 'Serwer chwilowo nie odpowiada. Sprawdź połączenie z internetem.',
                      type: 'error'
                    },
                    duration: AppGlobal.defaultSnackBarDuration
                  });
                  return throwError(null);

                default:
                  return throwError(error);
              }
            })
          );
        })
      );
    // }

  }
}
