import { createSelector } from '@ngrx/store';
import { getLoggedUserRoles, getParsedToken } from '../auth.reducer';
import { ParsedToken } from '../models/parsed-token';
import { Dictionary } from '../../../shared/models/dictionary';
import { Role, Roles } from '../auth-roles';
import * as _ from 'lodash';
import { AppRolesEnum } from '../enums/roles';
import { environment } from '../../../../environments/environment';

export const getUserRoles = createSelector(
  getParsedToken,
  (token: ParsedToken) => token && token.roles || []
);

export const getUserAdminPermission = createSelector(
  getLoggedUserRoles,
  (roles: Role[]) => !_.isEmpty(roles) ?
    roles.indexOf(Roles.admin) !== -1 : false
);

export const getUserSuperAdminPermission = createSelector(
  getLoggedUserRoles,
  (roles: Role[]) => !_.isEmpty(roles) ?
    roles.indexOf(Roles.superAdmin) !== -1 : false
);

export const getAppRoles = () => [
  { id: Roles.admin, name: 'Administrator' },
  { id: Roles.accessOffice, name: 'Biuro' },
  { id: Roles.accessService, name: 'Serwis' },
  { id: Roles.seller, name: 'Sprzedawca' },
  { id: Roles.accessWarehouse, name: 'Magazyn' },
  { id: Roles.valuer, name: 'Wyceniacz' }
] as Dictionary[];
