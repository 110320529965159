import { TimelineFieldNameKeys } from './timeline-field-name-keys';

export const TimelineFieldMap: { [key in TimelineFieldNameKeys]: string } = {
  name: 'Nazwa',
  description: 'Opis',
  amount: 'Ilość',
  part: 'Część',
  sellingPrice: 'Cena sprzedaży',
  purchasePrice: 'Cena zakupu',
  retailPrice: 'Cena detal',
  width: 'Szerokość',
  height: 'Wysokość',
  weight: 'Waga',
  length: 'Długość',
  quantity: 'Ilość - komplet',
  createdAt: 'Data utworzenia',
  thumbnail: 'Obrazek główny',
  unitState: 'Stan',
  basedOn: 'Nazwa części',
  based: 'Nazwa części',
  id: 'Identyfikator',
  phoneNumber: 'Telefon',
  city: 'Miejscowość',
  code: 'Kod pocztowy',
  number: 'Nr. ulicy',
  country: 'Kraj',
  street: 'Ulica',
  ebayAccount: 'Konto eBay',
  email: 'Email',
  surname: 'Nazwisko',
  acquisition: 'Pozyskanie',
  endedAt: 'Zakończenie projektu',
  startedAt: 'Rozpoczęcie projektu',
  estimationLaborCost: 'Estymacja kosztów pracy',
  partCost: 'Koszt części',
  status: 'Status',
  vin: 'VIN',
  isArchived: 'Czy zarchiwizowany',
  companyAddress: 'Adres firmy',
  addressData: 'Dane adresowe',
  conversation: 'Konwersacja',
  'addressData.city': 'Miasto',
  'addressData.country': 'Kraj',
  'addressData.province': 'Województwo',
  'addressData.apartment': 'Numer mieszkania/domu',
  'addressData.postalCode': 'Kod pocztowy',
  'addressData.streetName': 'Ulica',
  'location': 'Lokalizacja',
  'slug': 'Slug'
};
