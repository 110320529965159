import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EntityStoreModule } from './entity/entity-store.module';
import { HttpClientModule } from '@angular/common/http';
import { AuthStoreModule } from './auth/auth-store.module';
import { AuthEffectsModule } from './auth/auth-effects.module';
import { UserStoreModule } from './user/user-store.module';
import { InstanceStoreModule } from './instance/instance-store.module';
import { PartStoreModule } from './part/part-store.module';
import { ItemStoreModule } from './item/item-store.module';
import { DictionaryStoreModule } from './dictionary/dictionary-store.module';
import { DiagramStoreModule } from './diagram/diagram-store.module';
import { CarbonStoreModule } from './carbon/carbon-store.module';
import { LayerStoreModule } from './layer/layer-store.module';
import { ModelStoreModule } from './model/model-store.module';
import { TimelineStoreModule } from './timeline/timeline-store.module';
import { TemplateStoreModule } from './template/template-store.module';
import { ServiceStoreModule } from './service/service-store.module';
import { ClientStoreModule } from './client/client-store.module';
import { OfferStoreModule } from './offer/offer-store.module';
import { OrderStoreModule } from './order/order-store.module';
import { IcsStoreModule } from './ics/ics-store.module';
import { EbayStoreModule } from './ebay/ebay-store.module';
import { DiscountGroupStoreModule } from './discount-group/discount-group-store.module';
import { WarehouseStoreModule } from './warehouse/warehouse-store.module';
import { ApplicationStoreModule } from './application/application-store.module';
import { ArticleStoreModule } from './article/article-store.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),

    AuthStoreModule,
    AuthEffectsModule,
    EntityStoreModule,
    TemplateStoreModule,
    InstanceStoreModule,

    ArticleStoreModule,
    ApplicationStoreModule,
    CarbonStoreModule,
    ClientStoreModule,
    DiagramStoreModule,
    DictionaryStoreModule,
    DiscountGroupStoreModule,
    // EbayStoreModule,
    IcsStoreModule,
    ItemStoreModule,
    LayerStoreModule,
    ModelStoreModule,
    OrderStoreModule,
    OfferStoreModule,
    PartStoreModule,
    ServiceStoreModule,
    TimelineStoreModule,
    UserStoreModule,
    WarehouseStoreModule,

    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production })
  ]
})
export class AppStoreModule {
}
