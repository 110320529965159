import { Roles } from '../../app/store/auth/auth-roles';
import { AppMenuItem } from '../../app/shared/modules/app-menu/app-menu-item';

export const appMenu: AppMenuItem[] = [
  {
    label: 'Diagramy',
    path: '/diagram',
    roles: [Roles.admin]
  },
  {
    label: 'Magazyn',
    path: '/warehouse',
    roles: [Roles.admin, Roles.accessWarehouse]
  },
  {
    label: 'Lista modeli',
    path: '/model',
    roles: [Roles.admin]
  },
  {
    label: 'Lista części',
    path: '/part',
    roles: [Roles.admin, Roles.accessOffice, Roles.accessWarehouse]
  },
  {
    label: 'Lista egzemplarzy',
    path: '/item',
    roles: [Roles.admin, Roles.accessOffice, Roles.accessWarehouse]
  },
  {
    label: 'Oferty',
    path: '/offer',
    roles: [Roles.admin, Roles.accessOffice, Roles.seller]
  },
  {
    label: 'Zlecenia',
    path: '/order',
    roles: [Roles.admin, Roles.accessOffice, Roles.seller]
  },
  {
    label: 'Klienci',
    path: '/client',
    roles: [Roles.admin, Roles.accessOffice, Roles.seller]
  },
  {
    label: 'Serwis',
    path: '/service/office',
    roles: [Roles.admin, Roles.accessOffice]
  },
  // {
  //   label: 'Projekty',
  //   path: '/article',
  //   roles: [Roles.admin, Roles.accessOffice]
  // },
  // {
  //   label: 'Karbonownia',
  //   path: '/carbon',
  //   roles: [Roles.admin, Roles.accessOffice],
  //   children: [
  //     {
  //       label: 'Magazyn form',
  //       path: '/carbon/form'
  //     },
  //     {
  //       label: 'Zlecenia form',
  //       path: '/carbon/form-commission'
  //     },
  //     {
  //       label: 'Wytwarzanie części',
  //       path: '/carbon/part-commission'
  //     }
  //   ]
  // },
  {
    label: 'Ustawienia',
    path: '/settings',
    roles: [Roles.admin, Roles.accessOffice],
    children: [
      {
        label: 'Pracownicy',
        path: '/settings/user',
        roles: [Roles.admin]
      },
      // {
      //   label: 'Ustawienia Ebay',
      //   path: '/settings/ebay',
      //   roles: [Roles.admin]
      // },
      {
        label: 'Miejsca magazynowe',
        path: '/settings/warehouse'
      },
      // {
      //   label: 'Grupy rabatowe',
      //   path: '/settings/discount-group'
      // },
      {
        label: 'Słowniki',
        path: '/settings/dictionary',
        children: [
          {
            label: 'Kurier',
            path: '/settings/dictionary/courier'
          },
          {
            label: 'Producenci',
            path: '/settings/dictionary/brand'
          },
          {
            label: 'Materiały eksploatacyjne',
            path: '/settings/dictionary/material-exploitation'
          },
          // {
          //   label: 'Materiały karbonowni',
          //   path: '/settings/dictionary/carbon-material'
          // },
          {
            label: 'Operacje',
            path: '/settings/dictionary/operation'
          },
          {
            label: 'Procesy',
            path: '/settings/dictionary/process'
          },
          {
            label: 'Stany',
            path: '/settings/dictionary/unit-state'
          },
          {
            label: 'Tagi',
            path: '/settings/dictionary/tag'
          },
          // {
          //   label: 'Typ materiału karbonowni',
          //   path: '/settings/dictionary/carbon-material-type'
          // },
          // {
          //   label: 'Źródło pozyskania',
          //   path: '/settings/dictionary/acquisition'
          // }
        ]
      }
    ]
  },
  /**
   * Dla pracownika Serwisu
   */
  {
    label: 'Wyceny',
    path: '/service/commission',
    roles: [Roles.accessService, Roles.valuer]
  },
  {
    label: 'Naprawy',
    path: '/service/project',
    roles: [Roles.accessService, Roles.valuer]
  },
  /**
   * Dla pracownika Karbonowni
   */
  {
    label: 'Zlecenia form',
    path: '/carbon/employee/form-commission',
    roles: [Roles.accessCarbonHouse]
  },
  {
    label: 'Wytwarzanie części',
    path: '/carbon/employee/part-commission',
    roles: [Roles.accessCarbonHouse]
  }
];
