<ng-container *ngIf="hasActiveFilters; else strokedFilterButton">
    <button mat-raised-button
        class="app-xs"
        (click)="onClick.emit()"
        color="primary">
        <lib-icon icon="filter"></lib-icon>
        Filtry
    </button>
</ng-container>

<ng-template #strokedFilterButton>
    <button mat-stroked-button
        class="app-xs"
        (click)="onClick.emit()"
        color="primary">
        <lib-icon icon="filter"></lib-icon>
        Filtry
    </button>
</ng-template>