export const environment = {
  production: false,
  hosts: {
    apiUrl: 'https://api-acc-aw.dev.host06.300devs.com',
    // apiUrl: 'http://127.0.0.1:1082',
    ebayApiUrl: 'https://api.sandbox.ebay.com/commerce/taxonomy/v1/category_tree/0',
    sentry: 'https://21b7d4caed2a484e87642b6ca76aa8f0@sentry.host70.300devs.com/14'
  },
  fileSize: 8388608 // 8388608
};
