import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-filters-button',
  templateUrl: './filters-button.component.html',
  styleUrls: ['./filters-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersButtonComponent {
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();
  @Input() hasActiveFilters = false;
}
