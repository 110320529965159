import { Injectable } from '@angular/core';
import { IdType, ListStateToComponentAdapter } from '@industi/ngx-common';
import { Action, Selector } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';
import {
  DiscountGroupLoadListAction,
  DiscountGroupLoadListChangeParamsAction,
  DiscountGroupLoadListClearAction,
  DiscountGroupLoadListClearParamsAction
} from '../actions/list.actions';
import {
  createDiscountGroupListData,
  createDiscountGroupListErrors,
  createDiscountGroupListLoading,
  createDiscountGroupListParams,
  createDiscountGroupListSuccess,
  createDiscountGroupListTotalCount
} from '../selectors/get-load-list.selectors';
import { DiscountGroup } from '../models/discount-group';
import { DiscountGroupListParams } from '../models/discount-group-list-params';
import { AppGlobal } from '../../../shared/app-global';

@Injectable({
  providedIn: 'root'
})
export class DiscountGroupListAdapterService
  extends ListStateToComponentAdapter<DiscountGroup, DiscountGroupListParams> {

  apiItemToTableItem(apiModel: DiscountGroup): DiscountGroup {
    return apiModel;
  }

  changeParamsAction(id: IdType, params: DiscountGroupListParams): Action {
    return new DiscountGroupLoadListChangeParamsAction({ id, params });
  }

  clearAction(id: IdType): Action {
    return new DiscountGroupLoadListClearAction({ id });
  }

  clearParamsAction(id: IdType): Action {
    return new DiscountGroupLoadListClearParamsAction({ id });
  }

  getDataSelector(id: IdType): Selector<any, DiscountGroup[]> {
    return createDiscountGroupListData(id);
  }

  getErrorsSelector(id: IdType): Selector<any, HttpErrorResponse> {
    return createDiscountGroupListErrors(id);
  }

  getLoadingSelector(id: IdType): Selector<any, boolean> {
    return createDiscountGroupListLoading(id);
  }

  getParamsSelector(id: IdType): Selector<any, DiscountGroupListParams> {
    return createDiscountGroupListParams(id);
  }

  getSuccessSelector(id: IdType): Selector<any, boolean> {
    return createDiscountGroupListSuccess(id);
  }

  getTotalCountSelector(id: IdType): Selector<any, number> {
    return createDiscountGroupListTotalCount(id);
  }

  loadListAction(id: IdType): Action {
    return new DiscountGroupLoadListAction({ id });
  }

  tableParamsToApiParams(tableParams: DiscountGroupListParams): DiscountGroupListParams {
    return _.omitBy({
      ...tableParams,
      page: tableParams?.page || AppGlobal.listParams.page,
    } as DiscountGroupListParams, _.isNil);
  }

}
